//Header
const header = () => {
    const btnNav = document.querySelector('.btn-nav')
    const btnNavClose = document.querySelector('.btn-nav-close')
    const menuItems = document.querySelectorAll('.menu-item-has-children.menu-parent-item')

    btnNav.addEventListener('click', () => {
        btnNav.classList.toggle('is-open')
        document.querySelector('.header-nav').classList.toggle('is-open')
        document.querySelector('body').classList.toggle('is-open-modal')
    })

    btnNavClose.addEventListener('click', () => {
        btnNav.classList.toggle('is-open')
        document.querySelector('.header-nav').classList.toggle('is-open')
        document.querySelector('body').classList.toggle('is-open-modal')
    })

    if (window.innerWidth > 1023) {
        menuItems.forEach((menuItem) => {
            menuItem.addEventListener('mouseenter', () => {
                menuItem.classList.add('is-hover')
            })

            menuItem.addEventListener('mouseleave', () => {
                if (menuItem.classList.contains('is-hover')) {
                    menuItem.classList.remove('is-hover')
                }
            })
        })
    }
    else {
        menuItems.forEach((menuItem) => {
            menuItem.addEventListener('click', () => {
                menuItems.forEach((item) => {
                    if (menuItem !== item) {
                        item.classList.remove('is-hover')
                    }
                })

                menuItem.classList.toggle('is-hover')
            })
        })
    }

    let get_started = document.querySelector('.btn')

    get_started.addEventListener('click', (e) => {
        e.preventDefault()

        const modal = document.querySelector('#schedule-meeting-modal')

        modal.classList.toggle('open')
        document.querySelector('body').classList.toggle('is-open-modal')
        return false
    })
}

const image_feature = () => {
    const image_features = document.querySelectorAll('.section-image-feature.big-images')
    const dw = document.documentElement.clientWidth

    image_features.forEach(image_feature => {
        if (image_feature && dw > 767) {
            const items = image_feature.querySelectorAll('.item')

            items.forEach((parent) => {
                var item = parent.querySelector('.col-img')

                if (parent.querySelector('.col-blue')) {
                    item = parent.querySelector('.col-blue')
                }

                calcMarginRight(parent, item)

                if (parent.classList.contains('is-mission')) {
                    const small_description_bg = parent.querySelector('.small-description-bg')
                    const small_description = parent.querySelector('.small-description')

                    let description = small_description.getBoundingClientRect()

                    small_description_bg.style.height = description.height + 200 + 'px'

                    calcMarginRight(parent, small_description_bg)
                }
            })

            window.addEventListener("resize", () => {
                items.forEach((parent) => {
                    var item = parent.querySelector('.col-img')

                    if (parent.querySelector('.col-blue')) {
                        item = parent.querySelector('.col-blue')
                    }

                    calcMarginRight(parent, item)

                    if (parent.classList.contains('is-mission')) {
                        const small_description_bg = parent.querySelector('.small-description-bg')
                        const small_description = parent.querySelector('.small-description')

                        let description = small_description.getBoundingClientRect()

                        small_description_bg.style.height = description.height + 200 + 'px'

                        calcMarginRight(parent, small_description_bg)
                    }
                })
            })
        }
    })
}

const bga_modal = () => {
    const openMeetingBtns = document.querySelectorAll('.toggle-modal')
    const closeModals = document.querySelectorAll('.close-meeting-modal')

    openMeetingBtns.forEach((openMeetingBtn) => {
        openMeetingBtn.addEventListener('click', (e) => {
            e.preventDefault()

            const modal = document.querySelector('#' + openMeetingBtn.getAttribute('data-modal'))

            modal.classList.toggle('open')
            document.querySelector('body').classList.toggle('is-open-modal')
            return false
        })
    })

    closeModals.forEach((closeModal) => {
        closeModal.addEventListener('click', (e) => {
            e.preventDefault()

            const modal = closeModal.closest('.bga-modal')

            modal.classList.toggle('open')
            document.querySelector('body').classList.toggle('is-open-modal')
            return false
        })
    })

    const meetingModal = document.querySelector('.schedule-meeting-modal')
    const inputs = meetingModal.querySelectorAll('form input, form select')

    form_actions(inputs)

    document.addEventListener('wpcf7submit', function (event) {

        const form = meetingModal.querySelector('.wpcf7')

        if (('#' + form.id) == event.detail.apiResponse.into) {
            setTimeout(function () {
                window.location.reload()
            }, 2000)
        }
    }, false)
}

const globalForm = document.querySelector('.global-from')
if (globalForm) {

    const globalFormInputs = globalForm.querySelectorAll('form input, form select, form textarea')

    form_actions(globalFormInputs)
}

function form_actions(inputs) {
    inputs.forEach((input) => {
        input.addEventListener('focusin', () => {
            if (!input.value && !input.closest('.country-select') || input && input.closest('.country-select')) {
                input.closest('.form-item').classList.toggle('is-clicked')
            }
        })

        input.addEventListener('focusout', () => {

            if (input.closest('.investable')) {
                input.closest('.investable').classList.toggle('up')
            }

            if (!input.value)
                input.closest('.form-item').classList.toggle('is-clicked')
        })

        if (input.closest('.investable')) {
            input.addEventListener('click', () => {
                input.closest('.investable').classList.toggle('up')
            })
        }
    })
}

function gallery_slider() {
    const gallerySliders = document.querySelectorAll('.gallery-slider')
    gallerySliders.forEach((parent) => {
        const item = parent.querySelector('.splide')
        calcMarginRight(parent.querySelector('.container'), item)
    })

    window.addEventListener("resize", () => {
        gallerySliders.forEach((parent) => {
            const item = parent.querySelector('.splide')
            calcMarginRight(parent.querySelector('.container'), item)
        })
    })
}

function bga_splide() {
    const splides = document.querySelectorAll('.splide')

    splides.forEach((splide) => {

        const breakpoints = {
            320: {
                perPage: 1,
            },
            768: {
                perPage: parseFloat(splide.getAttribute('data-perpage')),
            },
        }

        const options = {
            type: splide.getAttribute('data-type'),
            arrows: parseInt(splide.getAttribute('data-arrows')),
            pagination: parseInt(splide.getAttribute('data-dots')),
            perPage: parseFloat(splide.getAttribute('data-perpage')),
            gap: parseInt(splide.getAttribute('data-gap')),
            perMove: 1,
            mediaQuery: 'min',
            breakpoints: breakpoints,
        }

        const slider = new Splide(splide, options)
        slider.mount()
    })
}

function faqs() {
    const faqs = document.querySelectorAll('.section-faq .faq-title')

    faqs.forEach((title) => {
        const section = title.closest('.section')
        const faq = title.closest('.faq')

        title.addEventListener('click', function () {
            faq.classList.toggle('active')

            closeFaq(section, faq)

            if (faq.classList.contains('active')) {
                openFaq(faq)
            } else {
                faq.querySelector('.faq-answer').style.setProperty('height', '0')
            }
        })
    })
}

function closeFaq(section, faq) {
    section.querySelectorAll('.faq').forEach((otherItem) => {

        if (otherItem !== faq) {
            otherItem.classList.remove('active')

            const answer = otherItem.querySelector('.faq-answer')
            answer.style.setProperty('height', '0')
        }
    })
}

function openFaq(faq) {
    faq.querySelector('.faq-answer').style.setProperty('height', `auto`)
}

function load_more() {
    const loadMore = document.querySelectorAll('.load-more')

    loadMore.forEach((btn) => {
        btn.addEventListener('click', function () {
            const paged = parseInt(btn.getAttribute('data-page'))
            const post_type = btn.getAttribute('data-post_type')

            const data = new FormData()

            data.append('action', 'get_articles')
            data.append('paged', `${paged}`)
            data.append('post_type', `${post_type}`)
            data.append('security', `${bgawealth.get_articles}`)

            fetch(bgawealth.ajax_url, {
                method: 'POST',
                credentials: 'same-origin',
                body: data,
            })
                .then((response) => response.json())
                .then((response) => {

                    if (response['hide']) {
                        btn.style.display = 'none'
                    }

                    document.querySelector('.articles .grid').insertAdjacentHTML(
                        'beforeend',
                        response['articles']
                    )
                })
                .catch((error) => {
                    console.error(error)
                })
        })
    })
}

function sort_by() {
    const selects = document.querySelectorAll('.select-sortable')

    selects.forEach((select) => {
        select.addEventListener('change', () => {
            const value = select.value

            const data = new FormData()

            data.append('action', 'sort_by')
            data.append('value', `${value}`)
            data.append('security', `${bgawealth.sort_by}`)

            fetch(bgawealth.ajax_url, {
                method: 'POST',
                credentials: 'same-origin',
                body: data,
            })
                .then((response) => response.json())
                .then((response) => {
                    select.closest('.sortable-section').querySelector('.sortable-container').innerHTML = response['events']
                })
                .catch((error) => {
                    console.error(error)
                })
        })
    })
}

function start_video() {
    const playBtns = document.querySelectorAll('.play-btn')

    playBtns.forEach((playBtn) => {
        playBtn.addEventListener('click', function () {
            const videoContainer = playBtn.closest('.video-container')
            const player = videoContainer.querySelector('iframe')

            videoContainer.classList.add('is-started')

            if( videoContainer.classList.contains('local-video') ){
                videoContainer.querySelector('.mejs-playpause-button').dispatchEvent(new Event('click'));
            }
            else{
                if (playBtn.classList.contains('youtube')) {
                    player.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
                }
                else {
                    var data = { method: "play" }
                    player.contentWindow.postMessage(JSON.stringify(data), "*")
                }
            }
        })
    })
}

function calcMarginRight(parent, item) {
    const dw = document.documentElement.clientWidth
    const imgPro = parent.getBoundingClientRect()
    const offsetLeft = parent.offsetLeft

    if (parent.classList.contains('left-img')) {
        item.style.marginLeft = '-' + offsetLeft + 'px'
    }
    else {
        const offsetRight = offsetLeft + imgPro.width
        const marginRight = dw - offsetRight
        item.style.marginRight = '-' + marginRight + 'px'
    }
}

const contactBtn = document.querySelector('.contact-btn')
const question = document.querySelector('.have-a-question')

if (contactBtn) {
    contactBtn.addEventListener('click', (e) => {
        e.preventDefault()
        question.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        return false
    })
}

const pensionOptionsBtn = document.querySelector('.section-pension .options .btn')
const sectionMore = document.querySelector('.section-learn-more')

if (pensionOptionsBtn) {
    pensionOptionsBtn.addEventListener('click', (e) => {
        e.preventDefault()
        sectionMore.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        return false
    })
}

function loadScripts() {
    header()
    image_feature()
    bga_modal()
    bga_splide()
    gallery_slider()
    faqs()
    load_more()
    sort_by()
    start_video()
}

if (document.readyState === 'complete' || document.readyState !== 'loading') {
    loadScripts()
} else {
    document.addEventListener('DOMContentLoaded', loadScripts)
}